import React, { useEffect, useState } from 'react';


const PrivacyPolicy = () => {
  const [htmlContent, setHtmlContent] = useState("");

  useEffect(() => {
    fetch('./PrivacyPolicy.html')
      .then(response => response.text())
      .then(content => {
        console.log('content: ', content);
        setHtmlContent(content);
      });
  }, []);

  return (
    <div className="flex justify-center">
      <div className="w-full max-w-screen-md pt-10 pb-10">
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </div>
    </div>
  );

}

export default PrivacyPolicy;
