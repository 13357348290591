import React from 'react';
import Navigation from '../components/Navigation';
import PrivacyPolicy from './PrivacyPolicy';
import Footer from '../components/Footer';


const Policy = () => {
  return (
    <div>
      <Navigation />
      <div className="px-5">
        <PrivacyPolicy />
      </div>
      <Footer />
    </div>
  );
};

export default Policy;
