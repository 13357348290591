import React, { useRef } from 'react'
import Navigation from './Navigation';
import Header from './Header';
import Features from './Features';
import Statement from './Statement';
import IconHover from './IconHover';
import Action from './Action';
import Footer from './Footer_new';

function Home() {
  const inputRef: any = useRef(null);

  const focusInput = () => {
    inputRef.current.focus();
  };
  return (
    <div>
      <Navigation handleClick={focusInput}  />
      <Header inputRef={inputRef} />
      <Features />
      <Statement />
      <IconHover />
      <Action handleClick={focusInput} />
      <Footer />
    </div>
  );
};

export default Home;
