import React, { useRef, useState } from 'react';
import axios from 'axios';
import Toast from '../common/Toast';
import { BETA_REPOMATCH_URL } from './constants';

function Header({ inputRef }: {inputRef: any}) {
  const [email, setEmail] = useState('');
  const [toastType, setToastType] = useState<'success' | 'warning' | null>(null);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [toastVisible, setToastVisible] = useState<boolean>(false);

  const onJoinWaitlistSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
        const response = await axios.post('/api/join_waitlist/', { email });

        // Handle response as required, assuming API returns a message in 'message' key
        if (response.status === 200) {
          setToastMessage(response.data.message);
          setToastType('success');
          setToastVisible(true);
          setTimeout(() => setToastVisible(false), 3000); // Hide toast after 3 seconds
        }
        // setNotification(response.data.message);
    } catch (error) {
        // Handle error (e.g., show a different notification or log the error)
        // setNotification("An error occurred. Please try again.");
        setToastType('warning');
        setToastVisible(true);
        setToastMessage('There was some issue while joining the waitlist!');
        // console.log('error: ', error);
    }
  };

  return (
    <div style={{ margin: "75px 0px" }} id="home">
      {/* <!-- Hero --> */}
      <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8">
        {/* <!-- Grid --> */}
        <div className="grid md:grid-cols-2 gap-4 md:gap-8 xl:gap-20 md:items-center">
          <div>
            <h1 className="block text-3xl font-bold text-gray-800 sm:text-4xl lg:text-6xl lg:leading-tight dark:text-white">Source from the top <span className="text-blue-600">3%</span> of developers on <span className="text-blue-600">Github</span></h1>
            {/* <p className="mt-3 text-lg text-gray-800 dark:text-gray-400">We use AI to analyze open source code contributions to identify and connect you with talented developers who precisely match your job requirements.</p> */}

            <div className="mt-3 grid space-y-3">
          {/* <p><a className="font-medium text-blue-600 sm:py-6 dark:text-blue-500" href={BETA_REPOMATCH_URL}>Try for free</a></p> */}
          {/* <p><a className="inline-flex gap-x-2 text-gray-400 hover:text-gray-200" href="#">Changelog</a></p>
          <p><a className="inline-flex gap-x-2 text-gray-400 hover:text-gray-200" href="#">Docs</a></p> */}
        </div>
        <a href={BETA_REPOMATCH_URL}>
          <button className="w-full sm:w-auto inline-flex justify-center items-center gap-x-3 text-center bg-blue-600 hover:bg-blue-700 border border-transparent text-white font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition py-3 px-4 dark:focus:ring-offset-gray-800" >
            Start Free
          </button>
        </a>
            {/* <div className="mt-4 lg:mt-8 flex flex-col items-center gap-2 sm:flex-row sm:gap-3">
              <Toast message={toastMessage} isVisible={toastVisible} type={toastType} />
              <form onSubmit={onJoinWaitlistSubmit} className="w-full flex flex-col items-center gap-2 sm:flex-row sm:gap-3">
                <div className="w-full sm:w-auto">
                  <input
                    ref={inputRef}
                    type="email"
                    id="hero-input"
                    name="hero-input"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="py-3 px-4 block w-full xl:min-w-[18rem] border-gray-200 shadow-sm rounded-md focus:z-10 focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                    style={{ border: "1px solid rgb(229 231 235)" }}
                    placeholder="Enter your email"
                  />
                </div>
                <button type="submit" className="w-full sm:w-auto inline-flex justify-center items-center gap-x-3 text-center bg-blue-600 hover:bg-blue-700 border border-transparent text-white font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition py-3 px-4 dark:focus:ring-offset-gray-800" >
                  Join the Waitlist
                </button>
              </form>
            </div> */}
            {/* <!-- End Buttons --> */}

          </div>
          {/* <!-- End Col --> */}

          <div className="relative ml-4">
            <img className="w-full rounded-md" src="images/intro-bg.png" alt="Image Description" />
            {/* <div className="absolute inset-0 -z-[1] bg-gradient-to-tr from-gray-200 via-white/0 to-white/0 w-full h-full rounded-md mt-4 -mb-4 mr-4 -ml-4 lg:mt-6 lg:-mb-6 lg:mr-6 lg:-ml-6 dark:from-slate-800 dark:via-slate-900/0 dark:to-slate-900/0"></div> */}

            {/* <!-- End SVG--> */}
          </div>
          {/* <!-- End Col --> */}
        </div>
        {/* <!-- End Grid --> */}
      </div>
      {/* <!-- End Hero --> */}
    </div>
  )
};

export default Header;