import React from 'react';
import BulletPoints from './BulletPoints';


const Statement = () => {
  return (
    <div>
      {/* <!-- Feature 1 (with content of Feature 2) --> */}
      <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto" style={{ margin: "40px auto" }}>
        {/* <!-- Grid --> */}
        <div className="md:grid md:grid-cols-2 md:items-center md:gap-12 xl:gap-32">
          <div className='pb-8'>
            <img className="rounded-xl" src="images/feature-2B.png" alt="Image Description" />
          </div>
          {/* <!-- End Col --> */}
          <div className="mt-5 sm:mt-10 lg:mt-0">
            <div className="space-y-6 sm:space-y-8">
              {/* <!-- Title --> */}
              <div className="space-y-2 md:space-y-4">
                <h2 className="font-bold text-3xl lg:text-4xl text-gray-800 dark:text-gray-200">
                  Find FAANG-level developers without busting your budget
                </h2>
                <p className="text-gray-500">
                
                  Our candidate pool comes from <strong>open-source developers</strong> who have demonstrated their coding skills while working with <strong>industry leaders</strong> such as Supabase, Docker, PostHog, and more. We believe that great talent is just <strong>hidden in opensight!</strong>
                </p>
              </div>
              {/* <!-- List --> */}
              {/* <ul role="list" className="space-y-2 sm:space-y-4">
                <BulletPoints text='Candidate pool sourced from open-source developers.' />
                <BulletPoints text='Demonstrated coding skills through collaborations.' />
                <BulletPoints text='Worked with industry leaders: Supabase, Docker, PostHog, etc.' />
                <BulletPoints text='Belief: Great talent is often hidden in plain sight' />
              </ul> */}
              {/* <!-- End List --> */}
              {/* <!-- End Title --> */}
            </div>
          </div>
          {/* <!-- End Col --> */}
        </div>
        {/* <!-- End Grid --> */}
      </div>
      {/* <!-- End Features --> */}

      {/* <!-- Feature 2 (with content of Feature 1) --> */}
      <div className='bg-gray-100'>
        <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto " style={{ margin: "40px auto" }}>
          {/* <!-- Grid --> */}
          <div className="flex flex-col-reverse md:grid md:grid-cols-2 md:items-center md:gap-12 xl:gap-32">
            <div className="mt-5 sm:mt-10 lg:mt-0">
              <div className="space-y-6 sm:space-y-8">
                {/* <!-- Title --> */}
                <div className="space-y-2 md:space-y-4">
                  <h2 className="font-bold text-3xl lg:text-4xl text-gray-800 dark:text-gray-200">
                    The easiest way to navigate Github without reading 1 line of code
                  </h2>
                  <p className="text-gray-500">
                    Use boolean search filters that are tailormade to surface hard-to-find tech talent:
                  </p>
                </div>
                {/* <!-- End Title --> */}
                {/* <!-- List --> */}
                <ul role="list" className="flex space-x-4">
                  <div className="space-y-2 sm:space-y-4 text-gray-500">
                    <BulletPoints text={'Job title'} />
                    <BulletPoints text={'Programming language'} />
                    <BulletPoints text={'Technical skills'} />
                    <BulletPoints text={'Industry'} />
                    <BulletPoints text={'Location'} />
                  </div>
                  {/* <div className="space-y-2 sm:space-y-4 text-gray-400">
                    <BulletPoints text={'Education and experience (coming soon)'} />
                    <BulletPoints text={'Company glassdoor ratings (coming soon)'} />
                    <BulletPoints text={'Layoff signals (coming soon)'} />
                    <BulletPoints text={'Career-change indicators (coming soon)'} />
                    <BulletPoints text={'Estimated salary (coming soon)'} />
                  </div> */}
                </ul>
                {/* <!-- End List --> */}
              </div>
            </div>
            {/* <!-- End Col --> */}
            <div>
              <img className="rounded-xl" src="images/3C.png" alt="Image Description" />
            </div>
            {/* <!-- End Col --> */}
          </div>
          {/* <!-- End Grid --> */}
        </div>
      </div>
      {/* <!-- End Features --> */}
      {/* Third Feature */}
      {/* <!-- Features --> */}
      <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto" style={{ margin: "40px auto" }}>
        {/* <!-- Grid --> */}
        <div className="md:grid md:grid-cols-2 md:items-center md:gap-12 xl:gap-32">
          <div>
            <img className="rounded-xl" src="images/feature-3A.jpg" alt="Image Description" />
          </div>
          {/* <!-- End Col --> */}

          <div className="mt-5 sm:mt-10 lg:mt-0">
            <div className="space-y-6 sm:space-y-8">
              {/* <!-- Title --> */}
              <div className="space-y-2 md:space-y-4">
                <h2 className="font-bold text-3xl lg:text-4xl text-gray-800 dark:text-gray-200">
                  Create engaging personalized outreach emails on the go
                </h2>
                <p className="text-gray-500">
                  We’re using ChatGPT to automate email outreach without removing the human touch. Under the hood, the algorithm learns which emails work best based on historic conversion rates across many users.
                </p>
              </div>
              {/* <!-- End Title --> */}
              <ul role="list" className="space-y-2 sm:space-y-4 text-gray-500">
                <BulletPoints text='Enhanced security with DMARC, DKIM, & SPF' />
                <BulletPoints text='Tailored and individualized messages' />
                <BulletPoints text='Delivery-optimized content' />
                <BulletPoints text='Smart email dispatch timings' />
                <BulletPoints text='Proactive bounce detection' />
              </ul>
            </div>
          </div>
          {/* <!-- End Col --> */}
        </div>
        {/* <!-- End Grid --> */}
      </div>
      {/* <!-- End Features --> */}
    </div>
  );
};

export default Statement;